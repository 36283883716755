<template>
  <div class="container">
    <h4 class="blue-text font-weight-bold">メールアドレス</h4>
    <b-alert
      class="m-0 mt-3 light-grey-outline grey-text"
      variant="warning"
      show
      v-if="isGoogleAccount"
    >
      <h5 class="m-0">
        <font-awesome-icon
          class="mr-3"
          :icon="{ prefix: 'fas', iconName: 'exclamation-circle' }"
          style="height: 25px; vertical-align: middle"
        />
        <span class="mt-2">
          この機能はGoogle認証を使わずに登録したアカウントで利用可能です。
        </span>
      </h5>
    </b-alert>
    <div class="row" v-else>
      <div class="col-12 col-md-12 col-lg-10 col-xl-5">
        <form v-on:submit="updateUserEmail">
          <div class="row">
            <div class="col-12">
              <div class="mt-4">
                <p class="m-0 grey-text">メールアドレス</p>
                <input
                  id="email"
                  class="form-control shadow-1"
                  type="email"
                  minlength="2"
                  maxlength="100"
                  v-model="email"
                  v-on:keyup="formMessage = null"
                  placeholder="メールアドレスを入力して下さい"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <b-alert
                class="m-0 mt-3 light-grey-outline grey-text"
                variant="warning"
                show
                v-if="formMessage !== null"
              >
                {{ formMessage }}
              </b-alert>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-2">
              <router-link
                :to="{
                  name: 'userRoute-dashboard',
                }"
              >
                <button class="btn btn-ds grey white-text">キャンセル</button>
              </router-link>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mt-2">
              <button
                class="btn btn-ds dark-blue white-text"
                type="submit"
                v-waves.light
              >
                更新
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <b-modal
      id="email-update-success-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('email-update-success-modal')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          メールアドレスが更新されました 。
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('email-update-success-modal')"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import { cus_userService_UpdateUserEmailByID } from '../../services/customer/users';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Profile | Email Update',
    };
  },

  data() {
    return {
      isGoogleAccount: false,
      email: null,
      formMessage: null,
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
  },

  mounted() {
    this.setUserProfile();

    setInterval(() => {
      this.googleAccountIsSignedIn();
    }, 500);
  },

  methods: {
    async googleAccountIsSignedIn() {
      let _this = this;

      try {
        let isAuthorized = await _this.$gAuth.isAuthorized;

        _this.isGoogleAccount = isAuthorized;
      } catch (error) {
        console.error(error);

        _this.isGoogleAccount = false;

        return null;
      }
    },

    setUserProfile() {
      this.email = this.currentUser.email;
    },

    updateUserEmail(e) {
      let _this = this;

      e.preventDefault();

      _this.$store.state.modalLoaderMessage = '保存中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      cus_userService_UpdateUserEmailByID({
        id: _this.currentUser.id,
        email: _this.email,
        token: accessToken,
      })
        .then(() => {
          _this.$store.state.currentUser.email = _this.email;

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
            _this.$bvModal.show('email-update-success-modal');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.formMessage =
            'メールアドレスの更新中にエラーが起こりました。再度お試しください。';

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
</style>
